import '@formkit/themes/genesis';
import '@formkit/pro/genesis';
import { generateClasses } from '@formkit/themes';
import theme from './formkit.theme';
import {
  close,
  down,
  fileDoc,
  check,
  circle,
  spinner,
  star,
  trash,
  add,
  arrowUp,
  arrowDown,
  applicationIcons,
  genesisIcons,
} from '@formkit/icons';
import { DefaultConfigOptions } from '@formkit/vue';
import { createProPlugin, inputs } from '@formkit/pro';
import { FormKitNode } from '@formkit/core';
import { toTitleCase } from '~/utils/helpers';

const pro = createProPlugin('fk-644908e468', inputs);

const isCheckboxAndRadioMultiple = (node: FormKitNode) =>
  (node.props.type === 'checkbox' || node.props.type === 'radio') && node.props.options;

function addOptionalLabelPlugin(node: FormKitNode) {
  node.on('created', () => {
    if (!node.props.definition) return;
    const schemaFn = node.props.definition.schema;
    node.props.definition.schema = (sectionsSchema = {}) => {
      const isRequired = node.props.parsedRules.some((rule) => rule.name === 'required');

      if (!isRequired) {
        if (isCheckboxAndRadioMultiple(node)) {
          sectionsSchema.legend = {
            attrs: {
              innerHTML: `${node.props.label} <span class="text-[20px] font-light text-gray-400">— Optional</span>`,
            },
          };
        } else {
          sectionsSchema.label = {
            attrs: {
              innerHTML: `${node.props.label} <span class="text-[20px] font-light text-gray-400">${
                node.name !== 'allowContact' ? '— Optional' : ''
              }</span>`,
            },
          };
          if (node.props.id === 'pathwayofhope') {
            sectionsSchema.label.attrs.class = 'formkit-label';
          }
        }
      } else {
        const containsHtmlTags = (str: string) => {
          // This regex matches any string that contains <tag>...</tag> structures, including self-closing tags.
          const regex = /<[^>]*>/;
          return regex.test(str);
        };

        if (containsHtmlTags(node.props.label)) {
          sectionsSchema.label = {
            attrs: {
              innerHTML: node.props.label,
            },
          };

          if (node.props.id === 'pathwayofhope') {
            sectionsSchema.label.attrs.class = 'formkit-label';
          }
        }
      }
      return schemaFn(sectionsSchema);
    };
  });

  node.on('created', async (event) => {
    if(node.props.type === 'number') {
      setTimeout(() => {
        if (document.getElementById(`${node.props.id}`) !== null) {
          document.getElementById(`${node.props.id}`)?.addEventListener('keydown', function (e) {
            if (e.key === 'e' || e.key === 'E') {
              e.preventDefault();
            }
          });
        }
      }, 1000);
    }
  });

  node.on('commit', async () => {
    if (!node.props.definition) return;
    if (
      [
        'first_name',
        'middle_name',
        'last_name',
        'firstName',
        'middleName',
        'lastName',
        'address1',
        'address2',
        'city',
        'firstname',
        'lastname',
        'landlordname',
        'landlordaddress',
        'landlordcity',
        'lendername',
        'lenderaddress',
        'lendercity',
        'utilityprovidername',
      ].includes(node.name)
    ) {
      await node.input(toTitleCase(node.value));
    }
  });

  node.hook.submit((payload, next) => {
    node?.children?.forEach((child) => {
      if (child.context?.type === 'mask' && child.context?.mask === '{#,##|repeat}#.##') {
        if (child.context?.value.length === 1) {
          payload[child.name] = `${parseInt(child.context?.value) * 100}`;
        }
      }
    });
    return next(payload);
  });
}

const config: DefaultConfigOptions = {
  messages: {
    en: {
      validation: {
        required() {
          return `This field is required.`;
        },
        min({ args }) {
          return `Must be at least ${args[0]}.`;
        },
      },
    },
  },
  config: {
    classes: generateClasses(theme),
  },
  plugins: [pro, addOptionalLabelPlugin],
  icons: {
    ...applicationIcons,
    ...genesisIcons,
    close,
    down,
    fileDoc,
    check,
    circle,
    spinner,
    star,
    trash,
    add,
    arrowUp,
    arrowDown,
    checkboxDecorator: check,
  },
};
export default config;

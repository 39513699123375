import type { VueQueryPluginOptions } from '@tanstack/vue-query';
import { VueQueryPlugin, QueryClient, hydrate, dehydrate } from '@tanstack/vue-query';
// Nuxt 3 app aliases
import { useState } from '#app';
import { useSessionStore } from '~/composables/stores/session';
export default defineNuxtPlugin((nuxtApp) => {
  // Modify your Vue Query global settings here
  const useSession = useSessionStore();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- ingore because we don't need to type errors
  const onError = async ({ response }: any) => {
    let errorMessage = `Please try again or contact support.`;
    let apiErrorMessage = '';

    // update apiErrorMessage from response?._data.error.message or response?._data.error.Message
    if (response?._data?.error?.message) {
      apiErrorMessage = response._data.error.message;
    } else if (response?._data?.error?.Message) {
      apiErrorMessage = response._data.error.Message;
    }

    if (response?.status === 401) {
      useSession.timeout();
    } else if (response?.status >= 400 && apiErrorMessage) {
      errorMessage = apiErrorMessage;
    } else if (apiErrorMessage) {
      errorMessage = apiErrorMessage;
    }

    if (errorMessage === 'The dto field is required.')
      errorMessage =
        'Please ensure all required fields are completed and retry your request. If the problem persists, please contact your local administrator.';

    if (errorMessage.includes('File upload error:')) {
      errorMessage = 'There is an issue with the file you are trying to upload. Please confirm your file is valid and retry your upload.';
    }

    toastError('Error', errorMessage, 5000);
  };

  const onSuccess = async () => {
    useSession.resetTimer();
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      mutations: {
        onError,
        onSuccess,
      },
      queries: {
        staleTime: 5000,
        refetchOnWindowFocus: false,
        // retryDelay: (attemptIndex) => Math.min(3000 * 2 ** attemptIndex, 30000),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- ingore because we don't need to type errors
        onError,
        onSuccess,
      },
    },
  });
  const options: VueQueryPluginOptions = { queryClient };

  nuxtApp.vueApp.use(VueQueryPlugin, options);
  const vueQueryClient = useState('@tanstack/vue-query');

  if (process.server) {
    nuxtApp.hooks.hook('app:rendered', () => {
      vueQueryClient.value = dehydrate(queryClient);
    });
  }

  if (process.client) {
    nuxtApp.hooks.hook('app:created', () => {
      hydrate(queryClient, vueQueryClient.value);
    });
  }
});

import { useErrorHandlerStore } from '~/composables/stores/error-handler';

export default defineNuxtPlugin((nuxtApp) => {
  const errorHandlerStore = useErrorHandlerStore();
  errorHandlerStore.setShowErrorMessage(false);

  nuxtApp.vueApp.config.errorHandler = (err, instance, info) => {
    console.error(err);
    /*if (info !== undefined && info !== null && typeof info === 'string' && info.includes('render')) {
      errorHandlerStore.setShowErrorMessage(true);
    }*/
  };
});

<template>
  <NuxtLayout>
    <NuxtPage :keepalive="false" :pageKey="route.fullPath" />
  </NuxtLayout>
</template>
<script lang="ts" setup>
const route = useRoute();
watch(route, () => {
  console.log('Navigating to a new page', route.fullPath);
});
</script>
